<template>
  <div class="">
    <section class="container">
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Manifestações tradicionais e festividades religiosas"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Além do patrimônio material herdado pela comunidade de Bento
                Rodrigues, foi também deixado pelos antepassados um legado que
                não pode ser tocado, porque não é material. A maneira como um
                grupo de pessoas se expressa por meio de suas festas, saberes,
                fazeres, de seus ofícios, celebrações e rituais realizados desde
                os antepassados e mantidos na vida da comunidade compõem a
                identidade de um povo.
              </p>
              <p>
                Entre as principais
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >festividades religiosas</router-link
                >
                tradicionalmente realizadas em Bento Rodrigues estão as Festas
                de São Bento, de Nossa Senhora das Mercês, de Santo Antônio, do
                Sagrado Coração de Jesus e de Nossa Senhora Aparecida. As missas
                eram acompanhadas de procissões, em que uma banda de música
                entoava os cânticos. As bandas geralmente eram de comunidades
                vizinhas que participavam das festividades como convidadas e
                eram recebidas na casa do festeiro, onde eram servidas as
                comidas e bebidas.
              </p>
              <p>
                Ressalta-se que as manifestações culturais vão além das práticas
                religiosas, sendo fundamental que continuem acontecendo para que
                não desapareçam. Até mesmo as festividades religiosas envolviam
                etapas de planejamento e organização entre os moradores,
                caracterizando essas reuniões ou encontros como um acontecimento
                social em si. A organização das festas incluía, por exemplo, a
                doação de produtos advindos dos próprios quintais para o preparo
                de comidas típicas. Moradores se juntavam na cozinha de uma das
                casas e no fogão, no forno à lenha eram feitos os assados, as
                quitandas, os cozidos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col cols="12" md="5" class="d-flex flex-column justify-center">
          <img
            width="100%"
            src="../../assets/sobre_territorio_bento_manifestacoes_culturais_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Festividade religiosa acompanhada de banda em Bento Rodrigues. Foto:
            Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container-fluid brown__stripe">
      <v-row
        class="container d-flex flex-md-row-reverse align-center justify-center justify-md-start"
      >
        <v-col cols="10" md="7" style="z-index: 10;">
          <Card borderColor="#e06919" class="mb-5" style="z-index: 5;">
            <template v-slot:conteudo>
              <p>
                Além das festas religiosas, em Bento havia o Grupo de Seresta de
                Bento Rodrigues, formado pelos violeiros José do Nascimento
                Jesus (Sr. Zezinho) e Oswaldo Apolinário Almeida e por dois
                vocalistas, Maria Irene de Jesus e José das Graças Caetano (Sr.
                Zezinho Café).
              </p>
              <p>
                Entre os pratos que faziam parte da cultura da comunidade,
                destaca-se a galinha caipira que era criada nos quintais e feita
                nos fogões a lenha. Também de grande relevância eram as coxinhas
                preparadas por Sandra Quintão, e comercializadas em seu bar
                situado ao lado da Capela de São Bento, com frente para a Praça
                Cônego Caetano Correa. O salgado é reconhecido pela comunidade
                como símbolo culinário, bastante procurado tanto por moradores
                como por visitantes. As coxinhas continuam a ser produzidas pela
                Sandra na sede de Mariana após o rompimento, e comercializadas
                para pessoas da cidade e atingidos de Bento Rodrigues que assim
                mantiveram o costume.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Outro produto que se destaca como característico do modo de
                fazer associado à culinária em Bento é a geleia de pimenta
                biquinho, preparada e comercializada pela Associação dos
                Hortifrutigranjeiros de Bento Rodrigues (AHOBERO). Com o
                propósito de vender frutas e legumes de forma processada, a
                associação foi fundada no ano de 2002. A comercialização da
                pimenta biquinho foi iniciada a partir de consultoria da EMATER5
                em 2006, e, com as pimentas que sobravam, iniciaram a produção
                da geleia, que continha na receita o suco de limão capeta,
                frutos colhidos nos quintais da comunidade. Com o rompimento da
                barragem de Fundão, a produção continuou na sede de Mariana;
                entretanto, o suco de limão não é mais o mesmo usado antes do
                rompimento que arruinou a vida da comunidade e alterou
                drasticamente o modo de vida dos moradores. Atualmente, a
                Associação utiliza imóvel alugado na sede de Mariana pelas
                empresas responsáveis pelo desastre–crime para a fabricação da
                geleia, sendo a matéria-prima adquirida de outros produtores, já
                que os terrenos onde eram cultivadas foram destruídos pelo
                rejeito.
              </p>
              <p>
                Entre os costumes que faziam parte da vida dos moradores de
                Bento está o futebol e o time local, o Unidos de Bento Rodrigues
                (U.B.R.), importante para a história e a união da comunidade.
                Destaca-se o time feminino, que conquistou várias medalhas e
                troféus. Além dos times de futebol feminino e masculino
                receberem visitantes para jogar na comunidade, iam para outros
                locais participar de campeonatos e assim praticamente todo final
                de semana estavam envolvidos com os jogos.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col cols="12" md="5" class="d-flex flex-column justify-center">
          <img
            width="100%"
            src="../../assets/sobre_territorio_bento_manifestacoes_culturais_02.png"
          />
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Empresa pública de assistência técnica e extensão
              rural do Estado de Minas Gerais, fundada em 1948.
            </p>
            <p style="margin-bottom: 0">
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}

.brown__stripe {
  position: relative;
}

.brown__stripe:before {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  height: 110px;
  width: 100%;
  background-color: #cd6b3e;
}
</style>
